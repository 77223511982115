$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").on('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollAnimation
    // *
    // *
    if ($(".js-scroll-animation").length) {
        $(window).scroll(function() {
            var viewPort = $(window).scrollTop() + $(window).height(),
                $scrollAnimation = $(".js-scroll-animation"),
                elementTop = $scrollAnimation.offset().top,
                offset = (($(window).height() / 2) + ($scrollAnimation.height() / 2));

            if ((elementTop + offset) <= viewPort) {
                $scrollAnimation.addClass("active");
            }else {
                $scrollAnimation.removeClass("active");
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggler
    // *
    // *
    $(".js-toggler-button").click(function(){
        $(this).parents(".js-toggler").toggleClass("active").find(".js-toggler-text").slideDown(200);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    var $slider = $(".js-slider");

    if ($slider.length) {
        $slider.each(function(){

            var $sliderWrapper = $(this).parents(".js-slider-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                waitForAnimate: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: $sliderWrapper.find(".js-slider-nav-prev"),
                nextArrow: $sliderWrapper.find(".js-slider-nav-next"),
                draggable: false,
                rows: 0,
            });

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length;
            $sliderWrapper.find(".js-slider-total").html(pad(total, 2));

            // set description
            var desc = $sliderWrapper.find(".slick-slide:eq(1)").find(".js-slider-item-desc").html();
            $sliderWrapper.find(".js-slider-desc").html(desc);

            // set fullscreen
            var url = $sliderWrapper.find(".slick-slide:eq(1)").find(".js-slider-image").data("url");
            $sliderWrapper.find(".js-slider-fullscreen").attr("href", url);

            // set current number, description and fullscreen
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var desc = $sliderWrapper.find(".slick-slide:eq(" + (nextSlide + 1) + ")").find(".js-slider-item-desc").html(),
                    url = $sliderWrapper.find(".slick-slide:eq(" + (nextSlide + 1) + ")").find(".js-slider-image").data("url");
                $sliderWrapper.find(".js-slider-current").html(pad((nextSlide + 1), 2));
                $sliderWrapper.find(".js-slider-desc").html(desc);
                $sliderWrapper.find(".js-slider-fullscreen").attr("href", url);
            });
        });
    }

    // formatting numbers to n digits: 1 => 01
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-nav-button").click(function(){
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");
        //$(".js-header").toggleClass("navActive");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fullscreenSlider
    // *
    // *
    if ($(".js-fullscreenslider").length) {
        $(".js-fullscreenslider").slick({
            infinite: true,
            fade: true,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3500,
            speed: 1000,
            waitForAnimate: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            touchMove: false,
            swipe: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            rows: 0,
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * load more
    // *
    // *
    $(".js-loadmore-button").each(function(){
        var $parent = $(this).parents(".js-loadmore-parent"),
            $wrapper = $parent.find(".js-loadmore-wrapper"),
            $pagination = $parent.find(".js-loadmore-pagination"),
            wrapperId = $wrapper.attr('id');

        $(this).click(function(){
            var url = $pagination.find("a.next").attr("href"),
                $nextLink = $pagination.find("a.next").removeClass("next").next("a").addClass("next");

            if (!$pagination.find("a.next").length) {
                $(this).fadeOut();
            }

            $.get(url).done(function(data){
                $(data).find("#" + wrapperId).find(".js-loadmore-item").appendTo($wrapper);
            });
        });

        // mark next navigation page for load more button on init
        $pagination.find("a.active").next("a").addClass("next");

        // hide button if not enough news on init
        if ($pagination.find("a").length <= 1) {
            $(this).hide();
        }
    });

});




// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function(){
    if($(window).scrollTop() >= 60) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }
});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * heroVideo
// *
// *
$(window).on("load", function(){
    if ($(".js-herovideo").length) {
        var url = $(".js-herovideo-video").data("url");
        $(".js-herovideo-fullscreen").attr("href", url);
    }
});
